/* eslint-disable no-console */
import React, { useContext } from 'react';

import { Box } from '@mui/joy';
import { Auth0LoginButton, Auth0LogoutButton } from '../Auth0';
import { Text } from 'src/mtska-frontend-app-component';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const SignIn = () => {

    const { appConfiguration, loginError } = useContext(AppContext);

    return (
        <Box className="app-login-panel">
            <Box className="logo" title={appConfiguration.fullName} align="center" >
                <div dangerouslySetInnerHTML={{ __html: appConfiguration.login.logoSvg }} />
            </Box>

            <Text level="h4">{appConfiguration.login.suiteName}</Text>
            <Text level="h1">{appConfiguration.app.fullName}</Text>
            <Text level="body-md">{appConfiguration.login.prompt}</Text>
            {
                loginError ? (
                    <>
                    <Text level="body-md error">{loginError}</Text>
                    <Auth0LogoutButton className="input-button big" size="regular" variant="solid" label="Retry"/>
                    </>
                ) : (
                    <Auth0LoginButton className="input-button big" />
                )
            }
        </Box>
    );
};

// export default withRouter(SignIn);
export default SignIn;

