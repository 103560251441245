import * as React from 'react';
// import Button from '@mui/material/Button';
import Button from './Button';

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Icon } from '../../assets';
import { Divider } from '@mui/material';

export default function SplitButton({ actions, disabled, initSelectedIndex = 1, showDivider = true }) {

    // disabled: true,
    // icon: "faInputNumeric",
    // label: t('Manage Plate'),
    // onClick: () => {}

    

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(initSelectedIndex)

    const fireClick = (index) => {
        actions[index]?.onClick && actions[index]?.onClick();
    }

    const handleClick = () => {
        fireClick(selectedIndex);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            {Array.isArray(actions) && <>
                <ButtonGroup
                    // disabled={disabled}
                    variant="contained"
                    ref={anchorRef}
                    aria-label="Button group with a nested menu"
                    className="buttongroup"
                >
                    <Button
                        disabled={actions[selectedIndex]?.disabled === true}
                        startIcon={<Icon icon={actions[selectedIndex]?.icon} />}
                        onClick={handleClick}>{actions[selectedIndex]?.label}</Button>
                    <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    sx={{ zIndex: 1 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    className="buttongroup_action_positioner"
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem
                                        className="buttongroup_action_wrapper"
                                    >

                                        {/* {actions.filter(el => !el.disabled).map((action, index) => ( */}
                                        {actions.map((action, index) => !action.disabled && (
                                            <MenuItem
                                                key={index}
                                                disabled={action?.disabled === true}
                                                selected={index === selectedIndex}
                                                onClick={(event) => {
                                                    handleMenuItemClick(event, index);
                                                    fireClick(index);

                                                }}
                                                className="buttongroup_action"

                                            >
                                                <Icon icon={action.icon} />
                                                {action?.label}
                                            </MenuItem>
                                        ))}
                                        {showDivider && <Divider/>}

                                        {/* {actions.filter(el => el.disabled).map((action, index) => ( */}
                                        {actions.map((action, index) => action.disabled && (
                                            <MenuItem
                                                key={index}
                                                disabled={action?.disabled === true}
                                                selected={index === selectedIndex}
                                                onClick={(event) => {
                                                    handleMenuItemClick(event, index);
                                                    fireClick(index);

                                                }}
                                                className="buttongroup_action"

                                            >
                                                <Icon icon={action.icon} />
                                                {action?.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>}
        </React.Fragment>
    );
}