import React, { useEffect, useContext, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Footer, Header, Aside, Main } from '../..';
import { AppContext } from '../../../contexts';
import { Box } from '@mui/joy';
import { ModuleMenu, useDrawer } from 'src/mtska-frontend-app-component';
import { useStyleVariableInjector } from 'src/mtska-frontend-app-scaffolding/utils/StyleVariableInjector';

const Full = () => {

    const { filterByLegalEntity, setCurrentModuleName, setCurrentModuleScope, setCurrentModuleNavigationData, appConfiguration, userCapabilities, settings } = useContext(AppContext);
    const { injectColorsToDocumentNode } = useStyleVariableInjector();

    const [isOpen, setIsOpen] = useState();
    const location = useLocation();

    useEffect(() => {
        for (const [key, module] of Object.entries(appConfiguration.modules.active)) {
            if (!module.hasOwnProperty('menuItemScope') || !module.hasOwnProperty('menuItemName')) continue;

            const moduleMenuGroup = appConfiguration.navigation[module.menuItemScope];
            const moduleMenuItem = moduleMenuGroup[module.menuItemName];

            if (moduleMenuItem == undefined) {
                console.info('ERRORE (Full): NON TROVATO', module.menuItemName, ' in ', module.menuItemScope, appConfiguration.navigation, ' Controlla se Module.jsx è fuori sincro con db');
                return;
            }

            if (moduleMenuItem && moduleMenuItem.href.split('?')[0] === location.pathname.split('?')[0]) {
                setCurrentModuleName(moduleMenuItem.title);
                setCurrentModuleScope(moduleMenuItem.scope);
            }
            if (moduleMenuGroup && moduleMenuItem.href.split('?')[0] === location.pathname.split('?')[0]) {
                setCurrentModuleNavigationData(moduleMenuGroup);
            }
        }
    }, [location]);


    const searchParams = new URLSearchParams(document.location.search)
    const { activate, hasRoute } = useDrawer();


    useEffect(() => {
        setIsOpen((sessionStorage.getItem('prefersAsideOpen') === 'true') ?? true);
        if (searchParams.has('drw')) {
            try {
                const drw = searchParams.get("drw");
                const dwrData = JSON.parse(atob(drw));
                const routeName = dwrData.routeName;
                const params = dwrData.params;

                if (hasRoute(routeName)) {
                    activate(routeName, params);
                }
            } catch (error) { }
        }

    }, [])

    const handleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    injectColorsToDocumentNode(appConfiguration.colors);

    const getDocumentClassName = () => {
        let cn = ['app'];

        cn.push((isOpen) ? 'open' : 'closed');
        cn.push(userCapabilities['auth/role.is_driver'] ? 'driver' : '');
        cn.push(userCapabilities['auth/role.is_carmanager'] ? 'carmanager' : '');
        cn.push(userCapabilities['auth/role.is_fleetmanager'] ? 'fleetmanager' : '');
        cn.push(userCapabilities['auth/role.is_superuser'] ? 'superuser' : '');

        cn.filter((cne) => cne !== '');

        return cn.join(' ');
    }

    return (
        <Box className={getDocumentClassName()}>
            <div className="superheader"></div>
            <Aside onOpenLayout={handleIsOpen} />
            <Header />
            <ModuleMenu />
            <Main>
                {((appConfiguration?.legalEntitySelector?.active && settings && filterByLegalEntity) || !appConfiguration?.legalEntitySelector?.active) && <Outlet /> }
            </Main>
            <Footer />
        </Box>
    );
};

export default Full;
