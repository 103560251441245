import React from "react";
import { Box } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { useFormatter } from "src/mtska-frontend-app-component/utils";
import { Icon } from "src/mtska-frontend-app-component/assets";
import { Tag } from ".";
import { Text } from "../Typography";

const DocumentAvatar = ({ document = null }) => {

    const { formatDate } = useFormatter();
    const { t } = useTranslation();

    const apiBasePath =  process.env.REACT_APP_API_ENDPOINT || '';

    return (
        <Box className="document-avatar">
            <Text className="label id">{t('name')}</Text>
            <Box className="value document-name">{document?.name}</Box>
            <Text className="label id">{t('type')}</Text>
            <Box className="value document-type">{t(document?.type)}</Box>
            <Box className="action">
                <a href={apiBasePath + document.url} target="_blank" class="buttonized input-button small MuiButton-variantSolid">{t('download')}</a>
            </Box>
        </Box>
    )
}

export default DocumentAvatar;