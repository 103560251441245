import React, { useContext, useEffect, useState } from 'react'
import { Box, ModalClose } from '@mui/joy';
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Cell } from '../ListElements'
import ListToolbar from './ListToolbar'
import { useDataProvider } from '../../../mtska-frontend-data-provider'
import { Text } from '../Typography'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'
import { pick } from 'dot-object'
import { BreadCrumb } from '../Drawer'
import { getCustomFilterOperators, getCustomLocaleText } from './list.functions'
import { CustomFooter } from './customFooter/CustomFooter';
import { AppContext } from 'src/mtska-frontend-app-scaffolding'
import { DrawerContext } from '../Drawer/DrawerContext'
import { useCellModes, useFullScreen, useModelSettings, useRows, useSearchFocus } from './list.hooks'

const listId = Math.random().toString(16).slice(2);

const pageSizeOptions = [5, 10, 15, 20, 100, 250, 500];

const List = ({
    removeSaveButton = false,
    title,
    apiRef,
    isDrawer,
    uid,
    handleClose,
    dataProviderName,
    isEmbedded,
    defaultSelectedValues,
    enableSelection,
    disableMultipleRowSelection,
    rowSelectionExternalListener,
    leftButtons = [],
    rightButtons = [],
    hideTableToolbar = false,
    className = "",
    filters: filtersFromProps,
    detailStack,
    dynamicColumns = false,
    onRowSelectionModelChange = () => { },
    onFilterPageSortChange = () => { },
    items,
    total,
    loading,
    onRowDoubleClick = () => { },
    doAction = () => { },
    doMassiveAction = () => { },
    maxRowSelection
}) => {

    const { t } = useTranslation()

    const { item: config } = useDataProvider('configs/config')

    const { innerHeight, saveSetting, settings, setSettings } = useContext(AppContext)

    const { actives = [] } = useContext(DrawerContext)
    const isNotDrawer = actives.length === 0

    const [columns, setColumns] = useState([])

    const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellModes()

    const elementId = 'dataGridProContainerId'
    const { isFullScreen, toggleFullscreen } = useFullScreen({ elementId })

    const {
        localSettings,
        model,
        setColumnOrderChange,
        setModelField,
        setPaginationModel
    } = useModelSettings({
        dataProviderName,
        filtersFromProps,
        isNotDrawer,
        loading,
        onFilterPageSortChange,
        saveSetting,
        setSettings,
        settings
    })

    const { columnOrder, filterModel, paginationModel, pinnedColumns = {}, sortModel, visibilityModel } = model

    const { processRowUpdate, rows } = useRows({ config, dataProviderName, doAction, items })

    const { isSearchFocused, searchRef, setIsSearchFocused } = useSearchFocus()

    // LOAD DATA prova commit

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCount` from being undefined during the loading
    const rowCountRef = React.useRef(total || 0);

    const rowCount = React.useMemo(() => {
        if (total !== undefined) {
            rowCountRef.current = total;
        }
        return rowCountRef.current;
    }, [total]);

    // *******************************************************************
    // MANAGE ROW SELECTION & ASSOCIATION
    // *******************************************************************

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const hadleRowSelectionModelChange = (newRowSelectionModel) => {
        const selectionModel = maxRowSelection ? newRowSelectionModel.slice(-maxRowSelection) : newRowSelectionModel
        setRowSelectionModel(selectionModel)
        rowSelectionExternalListener && rowSelectionExternalListener(selectionModel)
        onRowSelectionModelChange && onRowSelectionModelChange(rowSelectionModel)
    }

    const [viewedItems, setViewedItems] = useState([]);

    // preset rowSelectionModel and viewedItems
    useEffect(() => {
        if (!enableSelection) {
            return;
        }

        if (!Array.isArray(defaultSelectedValues)) {
            setRowSelectionModel([]);
            return;
        }

        const tmp = defaultSelectedValues.reduce((a, v) => ({ ...a, [v.id]: v }), {});
        setViewedItems(tmp);

        const selectedIemsIds = defaultSelectedValues.map(el => el._id);
        setRowSelectionModel(selectedIemsIds);

    }, [defaultSelectedValues, enableSelection]);

    const localHandleSubmit = () => {
        if (!enableSelection) return
        const selectedItems = rowSelectionModel.map(id => viewedItems[id])
        doMassiveAction({ selectedItems })
        handleClose(uid, selectedItems)
    }

    const handleRowDoubleClick = (row) => {
        onRowDoubleClick(row);
    }

    // add items to viewedItems when age or search change.. to have the full row object available for association
    useEffect(() => {
        if (!Array.isArray(items)) return;
        const tmp = { ...viewedItems, ...items.reduce((a, v) => ({ ...a, [v.id]: v }), {}) };
        setViewedItems(tmp);
    }, [items]);

    useEffect(() => {
        if (dynamicColumns || !localSettings || !config?.data?.modules[dataProviderName]?.tableConfig) return
        const { tableConfig = {} } = config?.data?.modules[dataProviderName]
        const { columns: columnOrder, columnVisibilityModel: visibilityModel } = tableConfig
        const defaultModel = { columnOrder, paginationModel: { page: 0, pageSize: 20 }, visibilityModel }
        if (Object.keys(model).length === 0) {
            if (localSettings[`${dataProviderName}.model`] && isNotDrawer) {
                setModelField({
                    ...defaultModel,
                    ...localSettings[`${dataProviderName}.model`]
                })
            } else if (columnOrder && visibilityModel) {
                setModelField(defaultModel)
            }
        }
    }, [dynamicColumns, localSettings])

    useEffect(() => {

        if (dynamicColumns || !settings || !config?.data?.modules[dataProviderName]?.tableConfig) return

        const { tableConfig } = config?.data?.modules[dataProviderName]

        if (Array.isArray(tableConfig?.columns)) {

            const { filterModel, visibilityModel } = model
            const { items = [] } = filterModel || {}

            const columns = tableConfig.columns.map((field) => {

                const {
                    editable,
                    options: valueOptions,
                    optionsEditable = {},
                    type: fieldType,
                    typeEditable
                } = tableConfig.columnsRendered[field] || {}

                const {
                    action,
                    dataProviderName: optionsDataProviderName,
                    filters,
                    optionKey,
                    optionLabel
                } = optionsEditable

                // campi filtri custom
                const value = items.find(item => item.field === field)?.value
                const customFilters = {}
                if (valueOptions || [/* 'currency',  */'date', 'datetime'].includes(fieldType)) {
                    customFilters.filterOperators = [getCustomFilterOperators({ options: valueOptions, t, type: fieldType, value })]
                }

                // campi celle custom
                const isCustomField = ['boolean'/* , 'currency' */, 'relationship'].includes(fieldType)
                const minWidth = ['date', 'datetime'].includes(fieldType) || ['relationship'].includes(typeEditable) ? 220 : 150
                const type = isCustomField
                    ? undefined
                    : valueOptions
                        ? 'singleSelect'
                        : fieldType === 'date' || fieldType === 'datetime'
                            ? fieldType
                            : 'string'

                return {
                    editable: typeEditable ? false : editable,
                    field,
                    flex: 1,
                    headerName: t(field.replace("_readable", "")
                        .replace("_calculated_", "")
                        .replace("_related_", "")
                        .replace("_related.", "")),
                    hideable: visibilityModel === false || tableConfig.columnsRendered[field]?.hideable === false ? false : true,
                    minWidth,
                    renderCell: (params) => {
                        const columnRendered = tableConfig.columnsRendered[params?.field]
                        const drawerRoute = tableConfig.columnsRendered[params?.field]?.drawerRoute
                        const fieldDataProviderName = tableConfig.columnsRendered[params?.field]?.providerName;
                        const val = typeof params?.row === 'object' ? pick(field, params.row) : params.value?.value;
                        return (
                            <Cell
                                action={action}
                                columnRendered={columnRendered}
                                configFilters={filters}
                                dataProviderName={dataProviderName}
                                detailStack={detailStack}
                                drawerRoute={drawerRoute}
                                field={params?.field}
                                fieldDataProviderName={fieldDataProviderName}
                                filters={filtersFromProps}
                                optionKey={optionKey}
                                optionLabel={optionLabel}
                                optionsDataProviderName={optionsDataProviderName}
                                processRowUpdate={processRowUpdate}
                                row={params.row}
                                type={columnRendered?.type}
                                typeEditable={typeEditable}
                                value={val}
                                valueOptions={valueOptions}
                            />
                        )
                    },
                    type,
                    valueOptions,
                    ...customFilters
                }
            })
            setColumns(columns)
        }

    }, [dynamicColumns, settings, model])

    useEffect(() => {
        if (!dynamicColumns) return;
        if (typeof items[0] === 'undefined') return;

        const cols = Object.getOwnPropertyNames(items[0]).reduce((a, v) => {
            if (v === 'id') return [...a];
            if (v === '_id') return [...a];
            if (v === 'job_id') return [...a];
            if (v === 'checks') return [...a];

            return [...a,
            {
                flex: 1,
                field: v,
                headerName: t(v),
                // cellClassName: (params) => {
                //     const error = params.row?.errors?.[params.field];
                //     return error;
                // },
                renderCell: (params) => {
                    // const columnRendered = tableConfig.columnsRendered[params?.field]
                    // const drawerRoute = tableConfig.columnsRendered[params?.field]?.drawerRoute
                    // const fieldDataProviderName = tableConfig.columnsRendered[params?.field]?.providerName;
                    const checks = params.row?.checks?.[params.field] ?? '';
                    const bgColor = checks?.exists === false ? 'red' : (checks?.changes === true) ? 'yellow' : '';
                    const color = checks === '' ? 'lightgray' : 'darkgreen';
                    const val = typeof params?.row === 'object' ? pick(v, params.row) : params.value?.value;
                    return (
                        <Box sx={{ backgroundColor: bgColor, color: { color } }}  >
                            {/* {checks && (<Chip color='warning'>{JSON.stringify(checks)}</Chip>)} */}
                            {val}
                        </Box>
                    )
                }
            }
            ]
        }, [
            // {
            //     field: 'actions',
            //     type: 'actions',
            //     getActions: (params) => [
            //         <GridActionsCellItem label="Import" showInMenu />,
            //         <GridActionsCellItem label="Ignore" showInMenu />,
            //         <GridActionsCellItem label="Delete" showInMenu />
            //     ]
            // }, 
            // {
            //     field: "checks",
            //     type: "string",
            //     valueGetter: (value, row) => {

            //         return JSON.stringify(value);
            //       },
            // }
        ]);
        setColumns(cols);

    }, [items])

    // forza display: 'none' a Checkbox selection 
    useEffect(() => {
        const observer = new MutationObserver(() => {
            document.querySelectorAll('.MuiFormControlLabel-root').forEach((el) => {
                if (el.textContent?.includes('Checkbox selection')) {
                    el.style.display = 'none'
                }
            })
        })
        observer.observe(document.body, { childList: true, subtree: true })
        return () => observer.disconnect()
    }, [])

    const orderedColumns = (columnOrder || []).map((colId) =>
        columns.find((column) => column.field === colId)
    ).filter(Boolean)

    return (
        <>
            {isDrawer && (
                <>
                    <Box className="drawer-section-header" >
                        <ModalClose />
                        <Text level="title-md" icon >{title}</Text>
                        {!removeSaveButton && (<Button label="Save" className="button-main" onClick={localHandleSubmit} />)}
                    </Box>
                    <BreadCrumb />
                </>
            )}
            <Box
                className={isEmbedded ? className + " form-element" : "drawer-section-content"}
                id={elementId}
                onClick={() => isFullScreen && toggleFullscreen()}
            >
                <Box className={(className + " list-system listy-system-" + listId).trim()}>
                    <DataGridPro

                        cellModesModel={cellModesModel}
                        onCellClick={handleCellClick}
                        onCellModesModelChange={handleCellModesModelChange}
                        processRowUpdate={processRowUpdate}
                        rows={rows || []}

                        apiRef={apiRef}
                        columns={orderedColumns}
                        columnVisibilityModel={visibilityModel}
                        filterModel={filterModel}
                        pinnedColumns={pinnedColumns}
                        sortModel={sortModel || []}
                        onColumnOrderChange={(params = {}) => setColumnOrderChange(params)}
                        onColumnVisibilityModelChange={(initVisibilityModel) => {
                            const visibilityModel = { ...initVisibilityModel, __check__: true }
                            setModelField({ visibilityModel })
                        }}
                        onFilterModelChange={(filterModel) => setModelField({ filterModel })}
                        onPinnedColumnsChange={(pinnedColumns) => setModelField({ pinnedColumns })}
                        onSortModelChange={(sortModel) => setModelField({ sortModel })}
                        slots={!hideTableToolbar ? { toolbar: ListToolbar, footer: CustomFooter } : { footer: CustomFooter }}
                        slotProps={{
                            toolbar: { isFullScreen, leftButtons, rightButtons, searchRef, setIsSearchFocused, toggleFullscreen },
                            footer: {
                                onPageChange: (newModel) => setPaginationModel(newModel),
                                pageSizeOptions,
                                paginationModel,
                                rowCount
                            },
                        }}
                        disableRowSelectionOnClick
                        checkboxSelection={enableSelection}
                        disableMultipleRowSelection={disableMultipleRowSelection}
                        onRowSelectionModelChange={hadleRowSelectionModelChange}
                        rowSelectionModel={rowSelectionModel}
                        getRowId={(row) => row._id}
                        autoHeight={true}
                        pagination
                        paginationMode="server"
                        rowsPerPage={10}
                        loading={loading}
                        rowCount={rowCount}
                        paginationModel={paginationModel}
                        hideFooter={!hideTableToolbar ? false : true}
                        filterMode="server"
                        sortingMode="server"
                        keepNonExistentRowsSelected
                        // onRowClick={handleRowDoubleClick}
                        onRowDoubleClick={handleRowDoubleClick}
                        className={"list-wrapper list-wrapper-" + listId}
                        headerFilters
                        localeText={getCustomLocaleText({ t })}

                        onStateChange={() => {
                            if (isSearchFocused && searchRef.current) {
                                searchRef.current.focus()
                            }
                        }}
                        sx={isFullScreen
                            ? {
                                '& .MuiDataGrid-main': {
                                    maxHeight: innerHeight - 165,
                                },
                                '& .MuiDataGrid-virtualScrollerRenderZone': {
                                    maxHeight: innerHeight - 165,
                                    overflowY: 'auto',
                                }
                            }
                            : {}
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default List
