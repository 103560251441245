import React, { useEffect } from 'react'
import { Box, List, ListItem } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { DocumentAvatar, DrivingLicenceAvatar, PersonAvatar, useDrawer } from 'src/mtska-frontend-app-component';
import { VehicleCard } from '../../Car/views';

const Driver = () => {
    const { t } = useTranslation();
    const { activate } = useDrawer();

    const { item: user } = useDataProvider('auth/token');
    const { loadAll: loadAllWhoAmI, items: myPerson } = useDataProvider('persons/person/whoami');
    const { loadAll: loadMyDrivingLicence, items: myDrivingLicence } = useDataProvider('persons/person/documents');
    const { loadAll: loadAllMyDocuments, items: myDocuments, total: myDocumentsTotal } = useDataProvider('documents/document');

    const { loadAll: loadAllMyCars, items: myCars, total: myCarsTotal } = useDataProvider('cars/car');

    useEffect(() => {
        loadAllWhoAmI();
        loadMyDrivingLicence();
        loadAllMyCars();
        loadAllMyDocuments();
    }, [user]);

    const handlePersonClick = () => {
        activate('persons/detail', { id: myPerson.id });
    }

    const handleDrivingLicenceClick = () => {
        activate('persons/drivingLicenceAssociation', { person: myPerson, multipart: true })
    }

    const handlePersonalPictureClick = () => {
        activate('persons/personalPictureAssociation', { person: myPerson, multipart: true })
    }

    return (
        <Box className="dashboard dashboard-driver">

            <Box className="dashboard-portion-title">{t('Your profile')}</Box>
            <Box className="dashboard-portion-driver-info">
                <PersonAvatar onClick={handlePersonClick}firstname={myPerson?.firstname} lastname={myPerson?.lastname} url={myPerson?.picture?.url} withname={true} detail={user?.data?.email} item={myPerson}/>
                <DrivingLicenceAvatar onClick={handleDrivingLicenceClick} documents={myDrivingLicence} />
            </Box>

            <Box className="dashboard-portion-title">{t('Your vehicles')}</Box>
            <Box className="dashboard-portion-subtitle">{t('You have {{myCarsTotal}} vehicles in your garage', { myCarsTotal: myCarsTotal })}</Box>

            <List className="dashboard-portion dashboard-menu">
                {
                    myCars.map((myCar, k) => (
                        <ListItem key={k} className="dashboard-portion-item">
                            <VehicleCard itemview={myCar} />
                        </ListItem>
                    ))
                }
            </List>

            <Box className="dashboard-portion-title">{t('Your documents')}</Box>

            <List className="dashboard-portion dashboard-menu">
                {
                    // @todo, ripristinare ciclo su myDocuments
                    /*
                    myDocuments.map((myDocument, k) => (
                        myDocument.visibleToDriver && (
                            <ListItem key={k} className="dashboard-portion-item">
                                <DocumentAvatar document={myDocument} />
                            </ListItem>
                        )
                    ))
                    */

                    myDrivingLicence.map((myDocument, k) => (
                        myDocument.visibleToDriver && ["fuelCardAssignment", "tollDeviceAssignment", "fuelCardAssignment", "vehicleAssignment", "insurance", "insuranceCertificate", "greenCard", "vehicleRegistration", "revisionCertificate", "deliveryReport", "drivingDelegation", "identityDocument", "thirdPartyDrivingAuthorization", "mad", "ztlPass", "drivingLicense", "privacy", "revision"].includes(myDocument.type) && (
                            <ListItem key={k} className="dashboard-portion-item">
                                <DocumentAvatar document={myDocument} />
                            </ListItem>
                        )
                    ))
                }
            </List>
        </Box>
    )

}

export default Driver;


