import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from 'src/mtska-frontend-app-component';
import { useDataProvider } from 'src/mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'documents/document';
const wizardName = 'Driving Licence association';
const wizardObject = 'Driving Licence';
const wizardAction = 'drivingLicenceAssociation';

const DrivingLicenceAssociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);

    // DATA PROVIDER
    const {
        item,
        save,
        errorMessage,
        errors,
        saving,
        success
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "document",
                "fieldsets": [
                    {
                        "name": "document",
                        "fields": {
                            "file": {
                                "type": "dropzone",
                                "className": "col-12",
                                "icon": "faFile"
                            },
                            "documentIdentifier": {
                                "type": "text",
                                "required": true,
                                "className": "col-3",
                                "icon": "faAddressCard"
                            },
                            "documentIssuer": {
                                "type": "text",
                                "required": true,
                                "className": "col-3",
                                "icon": "faBuilding"
                            },
                            "valid_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-3"
                            },
                            "expire_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-3"
                            },
                            "person": {
                                "type": "relationshipSingle",
                                "relation": {
                                    "drawerRoute": "persons"
                                },
                                "view": "person"
                            },
                        }
                    }
                ]
            }
        ],
        "validation": []
    }

    // DOCUMENT ****NON USA**** QUESTO PROCESSO DI CREAZIONE TRAMITE JOB
    // 
    // const handleSave = (data) => {
    //     doAction({action: wizardAction, model: dataProviderName, data: data}).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    // }

    const handleSave = async (data) => {
        save && save({ ...data, filters: params.filters }).then(() => {close()})
    }
    
    return <>
        <DetailComponent
            values={{
                "id": item?.data?._id,
                "person": params?.person,
                "type": "drivingLicense",
                "visibleToDriver": "true",
                "name": t("drivingLicence")+(params?.person?.lastname ? ' '+params?.person?.lastname : '')
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Add a {{wizardObject}} to {{firstname}} {{lastname}}', { wizardObject: wizardObject, firstname: params?.person?.firstname, lastname: params?.person?.lastname })}
                />
            }
            {...params}
        />
    </>
}

export default DrivingLicenceAssociation;


