import React from "react";
import { Box } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { useFormatter } from "src/mtska-frontend-app-component/utils";
import { Icon } from "src/mtska-frontend-app-component/assets";
import { Tag } from ".";
import { Text } from "../Typography";

const DrivingLicenceAvatar = ({ documents = null, onClick }) => {

    const { formatDate } = useFormatter();
    const { t } = useTranslation();

    const drivingLicences = documents?.filter((document) => document.type === 'drivingLicense')
    const drivingLicence = drivingLicences?.reduce((mostRecent, current) => {
        if (current.expire_at !== undefined && (!mostRecent || new Date(current.expire_at) > new Date(mostRecent.expire_at))) {
            return current;
        }
        return mostRecent;
    }, null);

    let drivingLicenceStatus;
    if (drivingLicence?.expire_at == undefined) {
        drivingLicenceStatus = 'missing';
    } else if (new Date(drivingLicence?.expire_at) > new Date()) {
        drivingLicenceStatus = 'active';
    } else {
        drivingLicenceStatus = 'expired';
    }

    return (
        <Box className="driving-licence-avatar">
            <Text className="label id">{t('drivingLicenceIdentifier')}</Text>
            <Box className="driving-licence-id">{drivingLicence?.documentIdentifier}</Box>
            <Text className="label expiration">{t('expire_at')}</Text>
            <Box className="driving-licence-expiration">{formatDate(drivingLicence?.expire_at)}</Box>
            <Text className="label status">{t('status')}</Text>
            <Tag className="driving-licence-status" onClick={onClick} color={drivingLicenceStatus == 'active' ? 'success' : "danger"} title={t(drivingLicenceStatus)}><Icon icon={drivingLicenceStatus == 'active' ? 'faCheck' : "faWarning"} />{t(drivingLicenceStatus)}</Tag>
        </Box>
    )
}

export default DrivingLicenceAvatar;