import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';

const dataProviderName = 'cars/car';
const wizardName = 'Status Management';
const wizardAction = 'statusManagement';
const wizardObject = 'Status';


const StatusManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);


    // DATA PROVIDER
    const {
        item,
        errorMessage,
        errors,
        saving,
        doAction,
    } = useDataProvider(dataProviderName, params.multipart);

    const modelName = existingModules.modelName

    const options = [
        { label: "in_configuration", value: "in_configuration" },
        { label: "in_offer", value: "in_offer" },
        { label: "in_order", value: "in_order" },
        { label: "available", value: "available" },
        { label: "running", value: "running" },
        { label: "closed", value: "closed" }
    ];

    const filterStatuses = (statuses, currentStatus) => {
        let foundCurrent = false;
        return statuses.reduce((acc, status) => {
            if (status.value === currentStatus) {
                foundCurrent = true;
            } else if (foundCurrent) {
                acc.push(status);
            }
            return acc;
        }, []);
    };
    const filteredOptions = userCapabilities['auth/role.is_superuser'] ? options : filterStatuses(options, item?.data?.status || "");

    const detailConfig = {
        "tabs": [
            {
                "name": "management",
                "fieldsets": [
                    {
                        "name": "management",
                        "fields": {
                            "statusFuture": {
                                "type": "select",
                                "options": filteredOptions,
                                "required": true,
                                "className": "col-8",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "statusFuture_at": {
                                "type": "date",
                                "className": "col-4",
                                "icon": "faCalendarHeart",
                            },
                        }
                    },
                    {
                        "name": "offer_details",
                        "fields": {
                            "offer_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-3",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "offerIdentifier": {
                                "type": "text",
                                "required": true,
                                "className": "col-3",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "offerStatus": {
                                "type": "select",
                                "options": [
                                    { "label": "received", "value": "received" },
                                    { "label": "accepted", "value": "accepted" },
                                    { "label": "rejected", "value": "rejected" }
                                ],
                                "required": true,
                                "className": "col-3",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "offer_expires_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-3",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "contractDuration": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faHourglass"
                            },
                            "contractMileage": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faRoad"
                            },
                            "contractFormula": {
                                "type": "select",
                                "options": [
                                    { "label": "longterm", "value": "longterm" },
                                    { "label": "midterm", "value": "midterm" },
                                    { "label": "shortterm", "value": "shortterm" }
                                ],
                                "required": true,
                                "className": "col-6",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "contractMode": {
                                "type": "select",
                                "options": [
                                    { "label": "final", "value": "final" },
                                    { "label": "preleasing", "value": "preleasing" },
                                    { "label": "substitute", "value": "substitute" }
                                ],
                                "required": true,
                                "className": "col-6",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "renter": {
                                "type": "relationshipSingle",
                                "required": true,
                                "relation": {
                                    "drawerRoute": "legalentities",
                                    "filter": {
                                        "items": [
                                            {
                                                "field": "supplier_type",
                                                "operator": "equals",
                                                "value": "renter"
                                            }
                                        ]
                                    }
                                },
                                "view": "legalEntity"
                            },
                            "economics": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "advanceFee": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "deposit": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "financialFee": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "serviceFee": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "fuelFee": {
                                "type": "number",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "insuranceFee": {
                                "type": "number",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "taxFee": {
                                "type": "number",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "totalLeaseFee": {
                                "type": "number",
                                "required": true,
                                "className": "col-12",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },

                            "document": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "offerDocument": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                        },
                        "visibilitycondition": [
                            [
                                "statusFuture",
                                "=",
                                "in_offer"
                            ]
                        ]
                    },
                    {
                        "name": "order_details",
                        "fields": {
                            "ordered_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "orderIdentifier": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "purchaseOrderIdentifier": {
                                "type": "text",
                                "required": true,
                                "className": "col-2",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "lotIdentifier": {
                                "type": "text",
                                "required": true,
                                "className": "col-2",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "orderDescription": {
                                "type": "textarea",
                                "required": true,
                                "className": "col-12",
                                "placeholder": "insert",
                                "icon": "faInputText"
                            },

                            "renewal": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "isRenewal": {
                                "type": "select",
                                "options": [
                                    { "label": "true", "value": "true" },
                                    { "label": "false", "value": "false" }
                                ],
                                "required": true,
                                "className": "col-4",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "renewedCarPlate": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "renewedCarContractEnd_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "delivery": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "desiredDelivery_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "estimatedDelivery_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "desiredDeliveryLocation": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faLocation"
                            },

                            "document": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "orderDocument": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                        },
                        "visibilitycondition": [
                            [
                                "statusFuture",
                                "=",
                                "in_order"
                            ]
                        ]
                    },
                    {
                        "name": "delivery_details",
                        "fields": {
                            "plate": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "registered_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "chassisIdentifier": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faInputNumeric"
                            },
                            "deliveryLocationName": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faShop"
                            },
                            "deliveryLocationAddress": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faLocation"
                            },
                            "deliveryLocationTelephone": {
                                "type": "text",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faTelephone"
                            },
                            "contractMode": {
                                "type": "select",
                                "options": [
                                    { "label": "preleasing", "value": "preleasing" },
                                    { "label": "substitute", "value": "substitute" },
                                    { "label": "final", "value": "final" }
                                ],
                                "required": true,
                                "className": "col-12",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },

                            "document": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "registrationDocument": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                            "mad": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                        },
                        "visibilitycondition": [
                            [
                                "statusFuture",
                                "=",
                                "available"
                            ]
                        ]
                    },
                    {
                        "name": "running_details",
                        "fields": {
                            "plate": {
                                "type": "text",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faInputNumeric",
                                "readonly":true
                            },
                            "initialMileage": {
                                "type": "number",
                                "required": true,
                                "className": "col-6",
                                "placeholder": "insert",
                                "icon": "faRoad"
                            },
                            "start_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "contractDuration": {
                                "type": "number",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "end_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "installationNotes": {
                                "type": "textarea",
                                "required": true,
                                "className": "col-12",
                                "placeholder": "insert",
                                "icon": "faInputText"
                            },

                            "document": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "insurance": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                            "vehicleRegistration": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                            "deliveryReport": {
                                "type": "dropzone",
                                "className": "col-12"
                            },

                        },
                        "visibilitycondition": [
                            [
                                "statusFuture",
                                "=",
                                "running"
                            ]
                        ]
                    },
                    {
                        "name": "closure_details",
                        "fields": {
                            "closureReason": {
                                "type": "select",
                                "options": [
                                    {
                                        "label": "endOfContract",
                                        "value": "endOfContract"
                                    },
                                    {
                                        "label": "earlyClosure",
                                        "value": "earlyClosure"
                                    },
                                    {
                                        "label": "theft",
                                        "value": "theft"
                                    },
                                    {
                                        "label": "totalLoss",
                                        "value": "totalLoss"
                                    }
                                ],
                                "className": "col-4",
                                "icon": "faCircleX"
                            },
                            "closed_at": {
                                "type": "date",
                                "className": "col-4",
                                "icon": "faCalendarXmark"
                            },
                            "closureMileage": {
                                "type": "number",
                                "className": "col-4",
                                "icon": "faRoad"
                            },
                            "closureLocationName": {
                                "type": "text",
                                "className": "col-4",
                                "icon": "faShop"
                            },
                            "closureLocationAddress": {
                                "type": "text",
                                "className": "col-4",
                                "icon": "faLocation"
                            },
                            "closureLocationTelephone": {
                                "type": "text",
                                "className": "col-4",
                                "icon": "faPhone"
                            },
                            "closureNotes": {
                                "type": "textarea",
                                "minrows": 6,
                                "className": "col-12",
                                "icon": "faInputText"
                            },
                            "documents": {
                                "type": "sectiontitle",
                                "className": "col-12",
                            },
                            "returnReport": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                            "activeClaimReport": {
                                "type": "dropzone",
                                "className": "col-12"
                            },
                            "passiveClaimReport": {
                                "type": "dropzone",
                                "className": "col-12"
                            }
                        },
                        "visibilitycondition": [
                            [
                                "statusFuture",
                                "=",
                                "closed"
                            ]
                        ]
                    }
                ]
            }
        ],
        "validation": []
    }

    const handleSave = (data) => {
        doAction({ action: wizardAction, model: dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    return <>
        <DetailComponent
            values={{ 
                ...Object.fromEntries(
                    Object.entries(item?.data || {}).filter(([key, value]) => 
                        key !== "_id" && !key.startsWith("_related") &&  (typeof value !== "object" || value === null)
                    )
                ),

                ...Object.fromEntries(
                    Object.entries(item?.data?.setup || {}).filter(([key, value]) => 
                        key !== "_id" && !key.startsWith("_related") &&  (typeof value !== "object" || value === null)
                    )
                ),
                
                ...Object.fromEntries(
                    Object.entries(item?.data?.contract || {}).filter(([key, value]) => 
                        key !== "_id" && !key.startsWith("_related") && (typeof value !== "object" || value === null)
                    )
                ),

                "renter" :  { _id  :item?.data?.contract?.renter?._id, name  :item?.data?.contract?.renter?.name, website  :item?.data?.contract?.renter?.website  },
                "id": item?.data?._id,
                ...(item?.data?.contract?._id ? { contract_id: item.data.contract._id } : {}),

                "statusFuture_at": new Date().toISOString().split('T')[0]
            }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t(wizardName)}
            canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
            header={
                <InfoHeader
                    view="wizard"
                    title={t(wizardName)}
                    subtitle={t('Select a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject: wizardObject, carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make + " " + item.data.setup?.model) })}
                />
            }
            {...params}
        />
    </>
}

export default StatusManagement;


