import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, List, ListItem } from '@mui/joy';
import { RouterAnchor } from "../RouterAnchor"
import { CircularProgress } from "@mui/joy";
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { Anchor } from '../Typography';

const AppMenu = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { userCapabilities, appConfiguration } = useContext(AppContext);
    const drivermenu = [
        {
            icon: 'faHome',
            href: '/dashboard',
            scope: 'dashboard'
        },
        {
            icon: 'faBell',
            href: '/notifications/user',
            scope: 'notification'
        }
    ]

    return (
        <Box className="app-menu">
            {userCapabilities && Object.keys(userCapabilities).length > 0 && !userCapabilities['auth/role.is_driver'] &&
                <List className="app-menu-portion">
                    {appConfiguration.modules.active.map((module, k) => {
                        //console.info('(AppMenu) valuto', module.menuItemName);
                        if (!module.hasOwnProperty('menuItemScope') || !module.hasOwnProperty('menuItemName')) {
                            //console.info('(AppMenu) ---- esco perché menuItemScope', module.menuItemName, module.hasOwnProperty('menuItemScope'), module.hasOwnProperty('menuItemName'));
                            return;
                        }
                        const moduleMenuGroup = appConfiguration.navigation[module.menuItemScope];
                        const moduleMenuItem = moduleMenuGroup[module.menuItemName];

                        if (moduleMenuItem == undefined) {
                            //console.info('ERRORE (AppMenu): NON TROVATO', module.menuItemName, ' in ', module.menuItemScope, appConfiguration.navigation, ' Controlla se Module.jsx è fuori sincro con db (stanza navigation di applications_app)');
                            return;
                        }

                        let isSelected = false;

                        if (moduleMenuItem.mainMenuVisibility !== true) {
                            //console.info('(AppMenu) ---- esco perché mainMenuVisibility',module.menuItemName, moduleMenuItem.mainMenuVisibility);
                            return;
                        }

                        if ((moduleMenuItem && userCapabilities) && !userCapabilities[moduleMenuItem.capability]) {
                            //console.info('(AppMenu) ---- esco perché mancano capability',module.menuItemName, moduleMenuItem.capability);
                            return;
                        }

                        if (location.pathname.split('?')[0] == moduleMenuItem.href.split('?')[0]) {
                            isSelected = true;
                        } else if (moduleMenuGroup) {
                            for (const key in moduleMenuGroup) {
                                if (location.pathname.split('?')[0] == moduleMenuGroup[key].href.split('?')[0]) {
                                    isSelected = true;
                                    break;
                                }
                            }
                        }

                        return (
                            ((moduleMenuItem && userCapabilities) && userCapabilities[moduleMenuItem.capability]) && (
                                <ListItem key={k} className={(isSelected) ? 'app-menu-item app-menu-item-current' : 'app-menu-item'}>
                                    <RouterAnchor icon={moduleMenuItem.icon} href={moduleMenuItem.href} underline="none">
                                        <span className="name">{t(moduleMenuItem.scope)}</span>
                                    </RouterAnchor>
                                </ListItem>
                            )
                        );
                    })}
                    {appConfiguration?.custom?.menu?.length > 0 && !userCapabilities['auth/role.is_carmanager'] && appConfiguration.custom.menu.map((menu, k) => (
                        <ListItem className={'app-menu-item app-menu-item-custom'} key={k}>
                            {
                                menu.href && (
                                    <Anchor icon={menu.icon} href={menu.href} target="_blank" underline="none">
                                        <span className="name">{t(menu.label)}</span>
                                    </Anchor>
                                )
                            }
                            {
                                menu.route && (
                                    <RouterAnchor icon={menu.icon} href={menu.route} underline="none">
                                        <span className="name">{t(menu.label)}</span>
                                    </RouterAnchor>
                                )
                            }
                        </ListItem>
                    ))}
                </List>
            }


            {userCapabilities && Object.keys(userCapabilities).length > 0 && userCapabilities['auth/role.is_driver'] &&
                <List className="app-menu-portion">
                    {drivermenu.map((moduleMenuItem, k) => {
                        return (
                            <ListItem key={k} className={(location.pathname == moduleMenuItem.href) ? 'app-menu-item app-menu-item-current' : 'app-menu-item'}>
                                <RouterAnchor icon={moduleMenuItem.icon} href={moduleMenuItem.href} underline="none">
                                    <span className="name">{t(moduleMenuItem.scope)}</span>
                                </RouterAnchor>
                            </ListItem>
                        )
                    })}

                </List>
            }

            {!userCapabilities &&
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
            }
        </Box>
    );
}

export default AppMenu;